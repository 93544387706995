import * as Turbo from "@hotwired/turbo"

import ahoy from "ahoy.js"
import Alpine from "alpinejs"

window.ahoy = ahoy
window.Alpine = Alpine

Alpine.data("Collapse", () => ({
  open: false,
  toggle() {
    this.open = !this.open
  }
}))

Alpine.start()
